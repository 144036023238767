<template>
  <!-- eslint-disable max-len -->
  <div class="relative h-full">
    <sidenav />
    <div class="relative lg:ml-64 flex flex-col min-h-full">
      <topbar />
      <header class="border-b-2 border-gray-200 bg-white">
        <div class="flex gap-5 px-4 lg:px-6 pt-3 pb-5">
          <div class="flex flex-grow items-center">
            <div class="flex-shrink-0">
              <img class="w-btn mr-3" src="brand-icon-color-swatch.svg" />
            </div>
            <h1 class="font-bold text-xl">Aparência</h1>
          </div>
          <div class="flex-shrink-0">
            <button class="button button-primary">
              Salvar
            </button>
          </div>
        </div>
      </header>
      <main class="px-4 lg:px-6 pb-5 flex-grow">
        <div class="flex-grow">
          <div class="pt-5">
            <div>
              <h3 class="font-bold text-lg mb-3">Checkout</h3>
              <div class="border divide-y divide-gray-200 rounded-md">
                <div class="lg:grid lg:grid-cols-12 items-center">
                  <div class="lg:col-span-3 px-4 pt-4 lg:pt-0">
                    <span class="font-semibold">Logotipo da loja</span>
                  </div>
                  <div class="lg:col-span-9 p-4">
                    <button class="button w-full lg:w-auto">
                      <PhotographIcon size="24"/>
                      <span>Selecionar arquivo</span>
                    </button>
                    <ul class="text-gray-500 text-sm leading-5 mt-2">
                      <li>Dimensões mínimas: 100x32px</li>
                      <li>Formatos aceitos: SVG, PNG, JPG</li>
                      <li>Tamanho máximo: 2MB</li>
                    </ul>
                  </div>
                </div>
                <div class="lg:grid lg:grid-cols-12 items-center">
                  <div class="lg:col-span-3 px-4 pt-4 lg:pt-0">
                    <span class="font-semibold">Cor do fundo</span>
                  </div>
                  <div class="lg:col-span-9 p-4">
                    <div class="color-swatch input-group lg:w-48">
                      <div class="input-control">
                        <div class="input-group-color"></div>
                        <input type="text" class="input-field" placeholder="#FFFFFF"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <div class="font-semibold mb-2">Pré-visualização</div>
              <div class="rounded-6 bg-primary-100 rounded-md px-4 py-1">
                <img srcset="treva@2x.png 2x" src="treva.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { PhotographIcon } from '@vue-hero-icons/outline';
import Sidenav from '../../Sidenav.vue';
import Topbar from '../../Topbar.vue';

export default {
  name: 'api',
  metaInfo: {
    title: 'API',
  },
  components: {
    PhotographIcon,
    Sidenav,
    Topbar,
  },
  data: () => ({
    openModal: false,
  }),
  methods: {
    showModal() {
      this.openModal = !this.openModal;
    },
  },
  mounted() {},
};
</script>
